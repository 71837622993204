import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IconGrid } from '@docs/helpers';
import { Playground } from 'docz';
import * as Icons from './';
import { Flex } from '../ui/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconography"
    }}>{`Iconography`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Icons add emphasis, familiarity, and scanability to adjacent text.`}</p>
    </blockquote>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <ol>
      <li parentName="ol">{`Base Set`}</li>
    </ol>
    <p>{`Streamline is a large library, sometimes with dozens of icons that represent the same thing. Below is a base set for common actions that we strive to limit ourselves to across applications. `}<a parentName="p" {...{
        "href": "https://streamlineicons.com"
      }}>{`https://streamlineicons.com`}</a></p>
    <IconGrid Icons={Icons} mdxType="IconGrid" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import * as Icons from '../../../icons'
`}</code></pre>
    <p>{`Render an icon as a react component by appending it's name after the `}<inlineCode parentName="p">{`Icons`}</inlineCode>{` import. Icons support the `}<inlineCode parentName="p">{`space`}</inlineCode>{`, `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` shorthands from styled-system. You can also pass in a fill.`}</p>
    <Playground __position={1} __code={'<Flex justifyContent=\"center\">\n  <Icons.Calculate width=\"32px\" height=\"32px\" />\n  <Icons.Search p={3} />\n  <Icons.Edit fill=\"#3369a3\" />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      IconGrid,
      Playground,
      Icons,
      Flex,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex justifyContent='center' mdxType="Flex">
    <Icons.Calculate width='32px' height='32px' />
    <Icons.Search p={3} />
    <Icons.Edit fill='#3369a3' />
  </Flex>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      